import React, { useMemo } from "react";
import Grid from "@mui/material/Grid";
import Iconize from "../../SvgIcon/Iconize";
import ReminderSvg from "../../../assets/icons/contract/inactive/time.svg";
import ReminderActiveSvg from "../../../assets/icons/contract/active/time.svg";
import DebtCollectionSvg from "../../../assets/icons/contract/inactive/icon_firma.svg";
import DebtCollectionActiveSvg from "../../../assets/icons/contract/active/icon_firma_active.svg";
import PaymentOrderSvg from "../../../assets/icons/provision/inactive/postkasten.svg";
import PaymentOrderActiveSvg from "../../../assets/icons/provision/active/postkasten.svg";
import LawsuitSvg from "../../../assets/icons/provision/inactive/behoerde.svg";
import LawsuitActiveSvg from "../../../assets/icons/provision/active/behoerde.svg";
import CrossSvg from "../../../assets/icons/common/inactive/kreuz.svg";
import CrossActiveSvg from "../../../assets/icons/common/active/kreuz.svg";
import CheckSvg from "../../../assets/icons/common/inactive/haken.svg";
import CheckActiveSvg from "../../../assets/icons/common/active/haken.svg";
import { translate } from "../../../services/translations/translations";
import _includes from "lodash/includes";
import Person from "../Common/Person/Person";
import Address from "../Common/Address/Address";
import MoneySvg from "../../../assets/icons/contract/inactive/icon_geld-einfordern.svg";
import MoneySvgActive from "../../../assets/icons/contract/active/icon_geld-einfordern_active.svg";
import GoodsSvg from "../../../assets/icons/contract/inactive/icon_ware-einfordern.svg";
import GoodsSvgActive from "../../../assets/icons/contract/active/icon_ware-einfordern_active.svg";
import { dateFieldDefault, emailDefault, isMoney, isPhoneNumberDefault, requiredValidator } from "../../../services/validationRules";
import { useForm } from "../../../provider/Form/FormProvider";
import ValidatorTextField from "../../FormFields/ValidatorTextField";
import Group from "../../Wizard/Group";
import moment from "moment";
import FormLegend from "../Common/FormLegend/FormLegend";
import ValidatorDateField from "../../FormFields/ValidatorDateField";
import NumberFieldConfigured from "../ConfiguredFormElements/NumberFieldConfigured/NumberFieldConfigured";
import { AbstractCase } from "../../../types/Entities/AbstractCase";

type ContractBaseDefendClaimProps = {
  product: AbstractCase;
};
export default function ContractBaseDefendClaim({ product }: ContractBaseDefendClaimProps) {
  const { values } = useForm();
  const exampleDate = useMemo(() => moment().subtract(1, "weeks").format("DD.MM.YYYY"), []);

  return (
    <>
      <Grid item xs={12}>
        <Group
          type="radio"
          name="defenseProcessStatus"
          question="Wie ist der Stand der Forderung gegen Sie gerade?"
          options={[
            {
              labelText: translate(`${product.name}.defenseProcessStatus.options.none.label`, values),
              labelIcon: <Iconize src={CrossSvg} alt={translate(`${product.name}.defenseProcessStatus.options.none.label`, values)} />,
              labelIconActive: <Iconize src={CrossActiveSvg} alt={translate(`${product.name}.defenseProcessStatus.options.none.label`, values)} />,
              value: "none",
            },
            {
              labelText: translate(`${product.name}.defenseProcessStatus.options.reminder.label`, values),
              labelIcon: <Iconize src={ReminderSvg} alt={translate(`${product.name}.defenseProcessStatus.options.reminder.label`, values)} />,
              labelIconActive: <Iconize src={ReminderActiveSvg} alt={translate(`${product.name}.defenseProcessStatus.options.reminder.label`, values)} />,
              value: "reminder",
            },
            {
              labelText: translate(`${product.name}.defenseProcessStatus.options.debtCollection.label`, values),
              labelIcon: <Iconize src={DebtCollectionSvg} alt={translate(`${product.name}.defenseProcessStatus.options.debtCollection.label`, values)} />,
              labelIconActive: (
                <Iconize src={DebtCollectionActiveSvg} alt={translate(`${product.name}.defenseProcessStatus.options.debtCollection.label`, values)} />
              ),
              value: "debtCollection",
            },
            {
              labelText: translate(`${product.name}.defenseProcessStatus.options.paymentOrder.label`, values),
              labelIcon: <Iconize src={PaymentOrderSvg} alt={translate(`${product.name}.defenseProcessStatus.options.paymentOrder.label`, values)} />,
              labelIconActive: (
                <Iconize src={PaymentOrderActiveSvg} alt={translate(`${product.name}.defenseProcessStatus.options.paymentOrder.label`, values)} />
              ),
              optionHintText: "Ein Mahnbescheid kommt immer in einem gelben Brief per Post.",
              value: "paymentOrder",
            },
            {
              labelText: translate(`${product.name}.defenseProcessStatus.options.enforcementOrder.label`, values),
              labelIcon: <Iconize src={PaymentOrderSvg} alt={translate(`${product.name}.defenseProcessStatus.options.enforcementOrder.label`, values)} />,
              labelIconActive: (
                <Iconize src={PaymentOrderActiveSvg} alt={translate(`${product.name}.defenseProcessStatus.options.enforcementOrder.label`, values)} />
              ),
              optionHintText: "Ein Vollstreckungsbescheid kommt immer in einem gelben Brief per Post.",
              value: "enforcementOrder",
            },
            {
              labelText: translate(`${product.name}.defenseProcessStatus.options.lawsuit.label`, values),
              labelIcon: <Iconize src={LawsuitSvg} alt={translate(`${product.name}.defenseProcessStatus.options.lawsuit.label`, values)} />,
              labelIconActive: <Iconize src={LawsuitActiveSvg} alt={translate(`${product.name}.defenseProcessStatus.options.lawsuit.label`, values)} />,
              optionHintText: "Sie haben wegen der Forderung gegen Sie ein Klageschreiben vom Gericht zugestellt bekommen.",
              value: "lawsuit",
            },
          ]}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {values.defenseProcessStatus === "paymentOrder" && (
        <>
          <Grid item xs={12}>
            <FormLegend>Wann haben Sie den Mahnbescheid erhalten?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="paymentOrderDate"
              label="Zustellung Mahnbescheid am"
              helperText={`Das Zustellungsdatum ist auf dem gelben Briefumschlag vermerkt. Bitte geben Sie unbedingt dieses Datum hier an. (z.B. ${exampleDate})`}
              validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault]}
            />
          </Grid>
        </>
      )}
      {values.defenseProcessStatus === "enforcementOrder" && (
        <>
          <Grid item xs={12}>
            <FormLegend>Wann haben Sie den Vollstreckungsbescheid erhalten?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="enforcementOrderDate"
              label="Zustellung Vollstreckungsbescheid am"
              helperText={`Das Zustellungsdatum ist auf dem gelben Briefumschlag vermerkt. Bitte geben Sie unbedingt dieses Datum hier an. (z.B. ${exampleDate})`}
              validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault]}
            />
          </Grid>
        </>
      )}
      {values.defenseProcessStatus === "lawsuit" && (
        <>
          <Grid item xs={12}>
            <FormLegend>Wann haben sie die Klage erhalten?</FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorDateField
              name="statementOfClaimFiledDate"
              label="Klage erhalten am"
              helperText={`Hier geht es darum, wann Ihnen die Klage zugestellt wurde. (z.B. ${exampleDate})`}
              validators={[requiredValidator("Bitte geben Sie das Datum an"), dateFieldDefault]}
            />
          </Grid>
        </>
      )}
      {_includes(["paymentOrder", "reminder", "lawsuit"], values.defenseProcessStatus) && (
        <>
          <Grid item xs={12}>
            <Group
              type="radio"
              name="contractualPartnerHasLawyer"
              question="Hat Ihr Vertragspartner bereits einen Anwalt eingeschaltet? Wenn Sie hier unsicher sind, oder bisher kein Schreiben von der Anwaltskanzlei erhalten haben, geben Sie hier einfach “Nein” an."
              options={[
                {
                  labelText: "Ja",
                  labelIcon: <Iconize src={CheckSvg} alt={"Ja"} />,
                  labelIconActive: <Iconize src={CheckActiveSvg} alt={"Ja"} />,
                  value: "yes",
                },
                {
                  labelText: "Nein",
                  labelIcon: <Iconize src={CrossSvg} alt={"Nein"} />,
                  labelIconActive: <Iconize src={CrossActiveSvg} alt={"Nein"} />,
                  value: "no",
                },
              ]}
              validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
            />
          </Grid>
        </>
      )}
      {values.contractualPartnerHasLawyer === "yes" && (
        <>
          <Person fieldsAreRequired={false} path="opponentLawyer" headline="Wie heißt der Anwalt/die Anwaltskanzlei des Vertragspartners?" />
          <Grid item xs={12}>
            <ValidatorTextField name="opponentLawFirm.name" label="Name der Kanzlei" />
          </Grid>
          <Address
            fieldsAreRequired={false}
            path="opponentLawFirm.address"
            headline="Welche Kontaktdaten des Anwalts/der Anwaltskanzlei liegen Ihnen vor? Diese finden Sie zum Beispiel auf dem Schreiben im Briefkopf."
          />
          <Grid item xs={12}>
            <ValidatorTextField name="opponentLawFirm.email" label="E-Mail-Adresse Anwalt" validators={[emailDefault]} />
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField name="opponentLawFirm.telephone" label="Telefonnummer Anwalt" validators={isPhoneNumberDefault} />
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Group
          type="radio"
          name="subjectOfDispute"
          question="Was fordert Ihr Vertragspartner von Ihnen?"
          options={[
            {
              labelText: translate(`${product.name}.subjectOfDispute.options.money.label`, values),
              labelIcon: <Iconize src={MoneySvg} alt={"Geld"} />,
              labelIconActive: <Iconize src={MoneySvgActive} alt={"Geld"} />,
              value: "money",
            },
            {
              labelText: translate(`${product.name}.subjectOfDispute.options.goods.label`, values),
              labelIcon: <Iconize src={GoodsSvg} alt={"Leistung"} />,
              labelIconActive: <Iconize src={GoodsSvgActive} alt={"Leistung"} />,
              value: "goods",
            },
          ]}
          validators={[requiredValidator("Bitte wählen Sie eine der angezeigten Antworten aus")]}
        />
      </Grid>
      {!!values.defenseProcessStatus && values.subjectOfDispute === "money" && (
        <>
          <Grid item xs={12}>
            <FormLegend>
              {!_includes(["reminder", "debtCollection"], values.defenseProcessStatus) && <>Wie viel Geld fordert Ihr Vertragspartner?</>}
              {_includes(["reminder", "debtCollection"], values.defenseProcessStatus) && (
                <>Wie viel Geld fordert Ihr Vertragspartner (ohne Mahn-/Inkassogebühren)?</>
              )}{" "}
              Sollten Sie die Forderung (noch) nicht genau kennen, geben Sie hier eine Schätzung oder einfach “0” an.
            </FormLegend>
          </Grid>
          <Grid item xs={12}>
            <ValidatorTextField
              label="Forderung in €"
              name="claimedAmountClient"
              validators={[
                requiredValidator("Bitte geben Sie Ihre Forderung an."),
                {
                  validator: isMoney,
                  message: "Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00",
                },
              ]}
              helperText="Bitte geben Sie den Wert in Euro und Cent ein - zum Beispiel 136,15 oder 85,00"
            />
          </Grid>
          {["reminder", "debtCollection"].includes(values.defenseProcessStatus) && (
            <NumberFieldConfigured caseData={product} fieldPath={"overdueFees"} fieldType={"money"} numberType={"float"} />
          )}
        </>
      )}
    </>
  );
}
