import {scrollToAnchor} from "../../../theme/commonStyle";

const serviceWorldMyCasesStyle = {
  myCasesContainer: {
    marginTop: 0,
  },
  expandClosedCases: {
    backgroundColor: "#f4f4f4",
    maxWidth: "500px",
    padding: "25px",
  },
  expandClosedCasesText: {
    textAlign: "center",
  },
  noCase: {
    textAlign: "center",
    color: "#999",
    padding: "25px 0",
  },
  divider: {
    backgroundColor: "#e6e6e6",
  },
  scrollToAnchor,
};

export default serviceWorldMyCasesStyle;
