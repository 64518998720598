import quotationMarkBackground from "../../../assets/img/background/Anfuehrungszeichen_oben_unten.svg";

const quotationCardStyle = {
  card: {
    width: "200px",
    height: "200px",
    backgroundImage: "url(" + quotationMarkBackground + "), linear-gradient(#FFFFFF, #F4F7FF)",
    backgroundRepeat: "no-repeat",
    margin: "auto",
  },
  textContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    padding: "0 1.5rem",
  },
};

export default quotationCardStyle;
