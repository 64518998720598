import React from "react";
import * as PropTypes from "prop-types";
import layoutStyle from "./layoutStyle";
import NavigationBar from "../../components/Navigation/NavigationBar/NavigationBar";
import Footer from "../../components/Footer/Footer";
import useMediaQuery from "@mui/material/useMediaQuery";
import NavigationBarMobile from "../../components/Navigation/NavigationBar/Mobile/NavigationBarMobile";
import backgroundStyling from "../../services/layoutService";
import ReleaseInfo from "../../components/ReleaseInfo/ReleaseInfo";
import ScrollTopButton from "../../components/Button/ScrollTopButton";
import { useCustomer } from "../../provider/Customer/CustomerProvider";
import { Box } from "@mui/material";

// type LayoutProps = {
//   minimal?: boolean;
//   clickableLogo?: boolean;
//   backgroundType?: string;
//   children?: React.ReactNode;
//   withScrollToTopButton?: boolean;
// };

export default function Layout({ minimal = false, clickableLogo = true, backgroundType, children, withScrollToTopButton = true }) {
  const { customer } = useCustomer();

  const isDesktopView = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const hideNavigation = (customer?.roles && customer.roles.includes("ROLE_ADVOCARD_FLEX_SERVICE")) || false;

  if (isDesktopView) {
    return (
      <>
        <ReleaseInfo />
        <Box sx={layoutStyle.pageContainer}>
          <Box sx={{ ...layoutStyle.content, ...(backgroundType ? backgroundStyling.desktop[backgroundType] : {}) }}>
            <NavigationBar minimal={minimal} clickableLogo={clickableLogo} hideMainNavigation={hideNavigation} />
            <Box sx={layoutStyle.childrenContainer}>{children}</Box>
            <Footer hideProductLinks={hideNavigation} />
          </Box>
        </Box>
        {withScrollToTopButton && <ScrollTopButton />}
      </>
    );
  }

  return (
    <>
      <ReleaseInfo />
      <Box sx={layoutStyle.pageContainer}>
        <Box sx={{ ...layoutStyle.content, ...(backgroundType ? backgroundStyling.mobile[backgroundType] : {}) }}>
          <NavigationBarMobile minimal={minimal} clickableLogo={clickableLogo} hideMainNavigation={hideNavigation} />
          <Box sx={layoutStyle.childrenContainer}>{children}</Box>
          <Footer hideProductLinks={hideNavigation} />
        </Box>
      </Box>
      {withScrollToTopButton && <ScrollTopButton />}
    </>
  );
}

Layout.propTypes = {
  minimal: PropTypes.bool,
  clickableLogo: PropTypes.bool,
  backgroundType: PropTypes.string,
};
