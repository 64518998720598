const greyToTransparentDividerStyle = {
  divider: {
    width: "100%",
    overflow: "hidden",
  },
  svg: {
    position: "relative",
    display: "block",
    width: "calc(129% + 1.3px)",
    height: "73px",
    "& path": {
      fill: "#F4F4F4",
    },
  },
};

export default greyToTransparentDividerStyle;
