import React, { useState } from "react";
import * as PropTypes from "prop-types";
import ContentBox from "../../Box/ContentBox/ContentBox";
import statusIcon from "../../../assets/icons/serviceOverview/icon_status_128px.png";
import Status from "../../Status/Status";
import _includes from "lodash/includes";
import { getCaseLink, getProductData } from "../../../services/productService";
import { Link } from "react-router-dom";
import { Button, List } from "@mui/material";
import _filter from "lodash/filter";
import DialogModal from "../../DialogModal/DialogModal";
import _map from "lodash/map";
import ServiceWorldCaseItem from "../MyCases/ServiceWorldCaseItem";
import { useQuery } from "@tanstack/react-query";
import { fetchCollection, queryKeys } from "../../../services/reactQuery/reactQueryService";

const CaseConfirmationModal = ({ open, handleClose, productClassName, currentCases }) => {
  const action = (
    <Button
      component={Link}
      to={"/servicewelt/" + getProductData(productClassName, "urlPartProductCategory") + "/" + getProductData(productClassName, "urlPartShort") + "/antrag"}
      variant="contained"
      color="primary"
      fullWidth
    >
      Neuen Fall anlegen
    </Button>
  );

  return (
    <DialogModal open={open} title={"Neuen Fall anlegen?"} onClose={handleClose} actions={action}>
      <p>
        Sie haben bereits einen Fall im Bereich{" "}
        <strong>
          {getProductData(productClassName, "productCategory.label")} - {getProductData(productClassName, "label")}
        </strong>
        .
      </p>
      <p>
        Möchten Sie tatsächlich einen neuen Fall anlegen? Dann klicken Sie auf "Neuen Fall anlegen”. Ansonsten können Sie Ihren bestehenden Fall hier öffnen:
      </p>
      <List disablePadding>
        {_map(currentCases, (minimalCase) => (
          <ServiceWorldCaseItem key={minimalCase.id} minimalCase={minimalCase} listItemButtonConfig={{getLink: (minimalCase) => getCaseLink(minimalCase)}}/>
        ))}
      </List>
    </DialogModal>
  );
};

const InitialStatusBox = ({ productClassName }) => {
  const [isCaseConfirmationOpen, setIsCaseConfirmationOpen] = useState(false);
  const [currentCases, setCurrentCases] = useState([]);
  const { data: minimalCases, isLoading } = useQuery(queryKeys.collection("/collection/cases"), () => fetchCollection("/collection/cases"));

  if (isLoading) {
    return null;
  }

  const handleClick = (event) => {
    const currentCasesInternal = _filter(minimalCases, (minimalCase) => minimalCase.name === productClassName);
    if (currentCasesInternal.length > 0) {
      event.preventDefault();
      setCurrentCases(currentCasesInternal);
      setIsCaseConfirmationOpen(true);
    }
  };

  return (
    <>
      <ContentBox icon={statusIcon} headline={"Status"} alignContent={"stretch"}>
        <Status
          ctaButton={
            <Button
              component={Link}
              to={
                "/servicewelt/" +
                getProductData(productClassName, "urlPartProductCategory") +
                "/" +
                getProductData(productClassName, "urlPartShort") +
                "/antrag"
              }
              onClick={handleClick}
              variant="contained"
              color="primary"
              fullWidth
              disabled={_includes(
                [
                  "alimony",
                  "death",
                  "familyLawCase",
                  "eventContract",
                  "membershipContract",
                  "otherContract",
                  "providerContract",
                  "dataLeakContract",
                  "purchaseContract",
                  "serviceContract",
                  "subscriptionContract",
                  "travelContract",
                  "vehicleContract",
                ],
                productClassName
              )}
            >
              Jetzt starten
            </Button>
          }
          maxPercentage={10}
          nextStepContent={{
            header: "Neuen Fall anlegen",
            text: "Vervollständigen Sie hier ganz unkompliziert Ihre Angaben. Wir kümmern uns anschließend umgehend um Ihr Anliegen.",
          }}
          productName={getProductData(productClassName, "label")}
        />
      </ContentBox>
      <CaseConfirmationModal
        productClassName={productClassName}
        open={isCaseConfirmationOpen}
        handleClose={() => setIsCaseConfirmationOpen(false)}
        currentCases={currentCases}
      />
    </>
  );
};

InitialStatusBox.propTypes = {
  productClassName: PropTypes.string.isRequired,
};

export default InitialStatusBox;
