import Layout from "../../../Layouts/Layout";
import React from "react";
import AccountPersonalData from "../../../../components/MyAccount/PersonalData";
import AccountPassword from "../../../../components/MyAccount/Password";
import AccountDelete from "../../../../components/MyAccount/AccountDelete";
import myAccountPageStyle from "./myAccountPageStyle";
import { Helmet } from "react-helmet-async";
import Paper from "@mui/material/Paper";
import ContentBoxHead from "../../../../components/Box/ContentBox/ContentBoxHead";
import iconPersonal from "../../../../assets/img/generic/icon_antrag_erstellen_150px.png";
import iconAccount from "../../../../assets/icons/generic/icon_servicewelt_256px.png";
import Grid from "@mui/material/Grid";
import PageNavigation from "../../../../components/Navigation/SummaryNavigation/PageNavigation";
import { Box } from "@mui/material";

export default function MyAccountPage() {
  const navigationLinks = [
    {
      target: "#personalData",
      label: "Daten ändern",
    },
    {
      target: "#password",
      label: "Passwort ändern",
    },
    {
      target: "#delete",
      label: "Konto löschen",
    },
  ];

  return (
    <Layout backgroundType={"serviceworld"}>
      <Helmet>
        <title>Mein Konto | Legalbird</title>
      </Helmet>
      <Box sx={myAccountPageStyle.container}>
        <PageNavigation navigationLinks={navigationLinks} headerIcon={iconAccount} headline={"Mein Konto"} />
        <Paper elevation={4} sx={myAccountPageStyle.paper}>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <ContentBoxHead headline={"Konto-Daten ändern"} altText={"Kontodaten ändern"} icon={iconPersonal} />
            </Grid>
          </Grid>
          <Box sx={myAccountPageStyle.scrollToAnchor} id={"personalData"} />
          <AccountPersonalData />
        </Paper>
        <Box sx={myAccountPageStyle.scrollToAnchor} id={"password"} />
        <AccountPassword />
        <Box sx={myAccountPageStyle.scrollToAnchor} id={"delete"} />
        <AccountDelete />
      </Box>
    </Layout>
  );
}
