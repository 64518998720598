import React from "react";
import processBoxStyle from "./processBoxStyle";
import {Grid, Typography} from "@mui/material";
import _map from "lodash/map";
import {getProcessConfig} from "../../../services/serviceWorldService";
import iconCheck from "../../../assets/icons/serviceOverview/icon_check_150px.png";
import iconProgress from "../../../assets/icons/serviceOverview/icon_progress_128px.png";
import ContentBox from "../../Box/ContentBox/ContentBox";
import PropTypes from "prop-types";

//pretty redundant right now, but in the future the content might differ quite a bit
const InitialProcessBox = ({productClassName}) => {
  const processConfig = getProcessConfig({name: productClassName, leadStatus: 10});

  if (!processConfig) {
    return null;
  }

  return (
    <ContentBox icon={iconProgress} headline={processConfig.headline} alignContent={"stretch"} loading={false}>
      <Grid container>
        {_map(processConfig.steps, (step, index) => (
          <React.Fragment key={index}>
            <Grid item xs={2}>
              <Grid container sx={processBoxStyle.iconContainer} direction="column" alignItems="center">
                {index === 0 ? <Grid item sx={processBoxStyle.connectorLineInvisible}/> :
                  <Grid item sx={processBoxStyle.connectorLine}/>}
                <Grid item sx={processBoxStyle.progressIcons}>
                  <img src={step.done ? iconCheck : step.icon} alt="Step Icon"/>
                </Grid>
                {index === processConfig.steps.length - 1 ? (
                  <Grid item sx={processBoxStyle.connectorLineInvisible}/>
                ) : (
                  <Grid item sx={processBoxStyle.connectorLine}/>
                )}
              </Grid>
            </Grid>
            <Grid item xs={10} sx={[processBoxStyle.textBlock, step.done ? processBoxStyle.done : null]}>
              <Grid component={"span"} sx={processBoxStyle.stepHeading}>
                {step.headline}
              </Grid>
              <Typography sx={processBoxStyle.stepText}>{step.content}</Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </ContentBox>
  );
};

InitialProcessBox.propTypes = {
  productClassName: PropTypes.string.isRequired,
};

export default InitialProcessBox;
