const formLegendStyle = {
  fieldset: {
    width: "100%",
  },
  label: {
    fontSize: "1.25rem",
    color: "#333",
    textAlign: "center",
    padding: "0 1rem",
    lineHeight: 1.5,
  },
};
export default formLegendStyle;
